<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">
    <aside>
      <div>提示：页面数据的日统计时间已延后{{statisticData.afterHour}}小时</div>
    </aside>
    <el-card class="SearchBox">
      <el-row>
        <el-form :model="searchFormData" :inline="true">
          <el-form-item label="渠道来源" prop="shopId">
            <el-select v-model="searchFormData.sceneId" placeholder="请选择" @change="handleChanged">
              <el-option v-for="item in sceneDrops" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-row>
    </el-card>
    <el-card class="SearchBox">
      <div id="myChart" :style="{width: '100%','min-height':'500px', height: '100%'}"></div>
    </el-card>
    <el-card>
      <div id="myChart2" :style="{width: '100%','min-height':'500px', height: '100%'}"></div>
    </el-card>
  </div>
</template>

<script>
import { getSceneOrderDateStatistic } from '@/service/statistics.js';
import { scenes } from '@/service/dropdown.js';

export default {
  data() {
    return {
      loading: false,
      sceneDrops: [
        {
          label: '--全部--',
          value: 0,
        }
      ],
      statisticData: {
        title: '',
        maxCount: 0,
        dateList: [],
        items: [],
        productList: []
      },
      searchFormData: {
        sceneId: 0,
        isDate: false,
      }
    }
  },
  mounted() {
    // this.drawLine();
  },
  created() {
    this.DataBinding();
  },
  methods: {
    //数据加载
    DataBinding() {
      this.loading = true;
      this.DropScenes();
      getSceneOrderDateStatistic(this.searchFormData).then(res => {
        console.log(res)
        this.statisticData = res.data;
        this.drawLineCount();
        this.drawLineMoney();
        this.loading = false;
      }).catch(err => {
        this.loading = false;
      });
    },
    //获取门店下拉框数据
    DropScenes() {
      scenes().then(res => {
        this.sceneDrops = [{
          label: '--全部--',
          value: 0,
        }];
        if (res.code == 0) {
          res.data.forEach(element => {
            this.sceneDrops.push(element);
          });
        }
      });
    },
    //选中门店
    handleChanged(shopId) {
      this.DataBinding();
    },
    //绘制图表
    drawLineCount() {
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      myChart.setOption({
        color: ['#3398DB'],
        title: {
          text: '数量统计--各渠道来源每月订单',
          subtext: this.statisticData.subTitle,
          x: 'left'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: [
          {
            type: 'category',
            data: this.statisticData.dateList,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            min: 0,
            max: this.statisticData.maxCount,
            type: 'value'
          }
        ],
        series: [
          {
            name: this.statisticData.countBarTitle,
            type: 'bar',
            barWidth: '60%',
            data: this.statisticData.countItems
          }
        ],
        dataZoom: {
          show: true,
          realtime: true,
          start: 0,
          zoomLock: false,
        }
      });
    },
    //绘制图表
    drawLineMoney() {
      let myChart = this.$echarts.init(document.getElementById('myChart2'))
      myChart.setOption({
        color: ['#3398DB'],
        title: {
          text: '收入统计--各渠道来源每月订单',
          subtext: this.statisticData.subTitle,
          x: 'left'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: [
          {
            type: 'category',
            data: this.statisticData.dateList,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            min: 0,
            max: this.statisticData.maxMoney,
            type: 'value'
          }
        ],
        series: [
          {
            name: this.statisticData.moneyBarTitle,
            type: 'bar',
            barWidth: '60%',
            data: this.statisticData.moneyItems
          }
        ],
        dataZoom: {
          show: true,
          realtime: true,
          start: 0,
          zoomLock: false,
        }
      });
    }
  }
};
</script>

<style scoped>
.SearchBox {
  margin-bottom: 30px;
}
</style>